import { throttle } from 'lodash-es';

import type {
  LogFunctionParameters,
  LogFunctionWithAdditionalCapabilities,
  LogType,
} from './types.js';

import { internalLogger, logLevelNumbers } from './constants.js';

export const createLogFunction = (
  logType: LogType,
  logLevel: LogType,
): LogFunctionWithAdditionalCapabilities => {
  const log = function (...args: LogFunctionParameters) {
    try {
      if (logLevelNumbers[logLevel] <= logLevelNumbers[logType]) {
        return internalLogger[logType](...args);
      }

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}; // mute logger when LOG_LEVEL is set to higher level
    } catch (e) {
      // eslint-disable-next-line no-restricted-globals
      console.error('Error in logger', e);
    }
  };

  const alreadyLoggedMessageId = new Set();
  let throttleMessageMap = new Map();

  log.once = (messageId: string, ...logParameters: LogFunctionParameters) => {
    if (!alreadyLoggedMessageId.has(messageId)) {
      alreadyLoggedMessageId.add(messageId);
      log(...logParameters);
    }
  };

  log.throttle = (
    delaySeconds: number,
    messageId: string,
    ...logParameters: LogFunctionParameters
  ) => {
    if (!throttleMessageMap.has(messageId)) {
      throttleMessageMap.set(
        messageId,
        throttle(log, delaySeconds * 1000, {
          trailing: false,
        }),
      );
    }
    throttleMessageMap.get(messageId)(...logParameters);
  };

  log.resetThrottle = () => {
    throttleMessageMap = new Map();
  };

  return log;
};
