import type { Target } from '@schibsted/pulse-news-media';
import type { PulseConfigOptions } from './pulse-v4.js';

import { abTestValue, userAbGroup } from '../ab-test.js';

// resolve local storage - Consider move to paywall module for reuse fasten/farticle
function hasLocalStorage() {
  let storage: null | Storage = null;
  try {
    storage = window.localStorage;
    if (
      !storage ||
      !storage.setItem ||
      !storage.getItem ||
      !storage.removeItem
    ) {
      storage = null;
    } else {
      // try to store and retrieve to trigger security exceptions
      storage.setItem('test', 'test');
      storage.removeItem('test');
    }
  } catch (err) {
    console.log(
      'Unable to get a working localStorage instance, probably security settings:',
      err,
    );
    storage = null;
  }

  return storage;
}
export function curateData(data: boolean | PulseConfigOptions) {
  const curateStorage = hasLocalStorage();
  const CURATE = 'curate';
  const EXPERIMENTS = 'experiments';
  const newData: Record<string, unknown> = {};
  if (curateStorage) {
    if (data) {
      setField(EXPERIMENTS);
      setField(CURATE);
    } else {
      getField(EXPERIMENTS);
      getField(CURATE);

      return newData;
    }
  }

  return false;
  function getField(field: string) {
    if (!curateStorage) {
      return;
    }

    const curField = curateStorage.getItem(field);
    if (curField !== null) {
      newData[field] = JSON.parse(curField);
    }
  }
  function setField(field: string) {
    if (!curateStorage) {
      return;
    }

    if (Object.prototype.hasOwnProperty.call(data, field)) {
      curateStorage.setItem(
        field,
        JSON.stringify(data[field as keyof typeof data]),
      );
    }
  }
}
export async function configureExtendedConfig(
  extendedConfig: PulseConfigOptions,
) {
  if (window.CURATE_CONFIG && window.CURATE_CONFIG.variant) {
    extendedConfig.curate = window.CURATE_CONFIG;
  }
  if (window.CUSTOM_PAGE_CONFIG) {
    extendedConfig.trackTimeSpent = window.CUSTOM_PAGE_CONFIG.trackTimeSpent;
    extendedConfig.pageId = window.CUSTOM_PAGE_CONFIG.pageId;
  }

  if (window.OPINION_CONFIG && window.OPINION_CONFIG.trackTimeSpent) {
    extendedConfig.trackTimeSpent = true;
    extendedConfig.pageId = 'opinion-form';
  }
  extendedConfig.abTest = {
    userABGroup: await userAbGroup(),
    abTestValue: abTestValue(),
  };
}
export async function prepReferringImpression(element: HTMLElement) {
  if (!element) {
    return;
  }

  const dataPulsePosition = element.getAttribute('data-pulse-position');

  // impression meta data - pageViewId now added by plugin
  const impression = {
    entityId: getAttribute(element, 'data-pulse-entity-id'),
    position: dataPulsePosition ? parseInt(dataPulsePosition, 10) : null,
    source: getAttribute(element, 'data-pulse-source'),
    abTestValue: abTestValue(),
    userABGroup: await userAbGroup(),
    pxFromTop: element.offsetTop,
    productName: getAttribute(element, 'data-pulse-product-name'),
    withSummary: getAttribute(element, 'data-pulse-with-summary'),
    experiments: getAttributeJson(element, 'data-pulse-experiments'),
  };
  window.pulse('defaultTracker.localHistory.updatePersistedImpressionHistory', {
    impression,
  });

  return;
}

export type PulseExperiment = {
  '@id': string;
  id: string;
  platform: string;
  variant: string;
  name: string;
};

export type PulseAttributes = {
  entityType?: string;
  entityId?: string;
  entityName?: string;
  entityTargetId?: string;
  entityTargetName?: string;
  entityTargetUrl?: string;
  entityImageUrl?: string;
  elementSize?: string;
  source?: string;
  position?: number;
  positionInLevel?: number;
  positionInBundle?: number;
  accessLevel?: string;
  accessLevelName?: string;
  cardSize?: string;
  url?: string;
  teaserImage?: string;
  title?: string;
  sourceNewsroom?: string;
  sourceArticleId?: string;
  levelTemplateName?: string;
  teaserFeatures?: string;
  forceToAmplitude?: string;
  clientName?: string;
  provider?: string;
  drEditionTeaserId?: string;
  adVersion?: string;
  drEditionTeaserCreativeId?: string;
  drEditionEditionId?: string;
  productName?: string;
  bundleId?: string;
  withSummary?: string;
  placement?: string;
  algorithm?: string;
  height?: number;
  width?: number;
  experiments?: PulseExperiment[];
  hasPersonalizationScore: number;
  seenCount: number;
  hasBeenRead: number;
};

function getAttribute(
  element: Element,
  attributeName: string,
): string | undefined {
  return element.getAttribute(attributeName) || undefined;
}

function getAttributeJson<T>(
  element: Element,
  attributeName: string,
): T | undefined {
  const stringValue = getAttribute(element, attributeName);

  if (!stringValue) {
    return undefined;
  }

  let value = undefined;

  try {
    value = JSON.parse(stringValue);
  } catch (error) {
    console.error('Error parsing JSON pulse metadata: ', stringValue, error);
  }

  return value;
}

function getAttributeNumberOrNull(element: HTMLElement, attributeName: string) {
  return element.hasAttribute(attributeName)
    ? Number(element.getAttribute(attributeName))
    : null;
}

function getUrl(element: HTMLElement): string | undefined {
  return (
    element.getAttribute('data-pulse-url') ||
    element.getAttribute('href') ||
    undefined
  );
}

export function createPulseProvider(pulseMeta: PulseAttributes) {
  const drEditionData: { productTag?: string } = {};
  if (pulseMeta.entityType === 'AD') {
    drEditionData.productTag = 'partnerstudio';
  }

  if (pulseMeta.forceToAmplitude === 'true') {
    return {
      component: 'schibsted-abo',
      ...drEditionData,
    };
  }

  return {
    ...drEditionData,
  };
}

function getEntityType(element: Element) {
  if (element.tagName === 'ARTICLE') {
    return element.hasAttribute('data-dr-edition-id') ? 'AD' : 'ARTICLE';
  }

  return 'LINK';
}

export function getPulseMeta(element: HTMLElement): PulseAttributes {
  return {
    entityType: getEntityType(element),
    entityId: getAttribute(element, 'data-pulse-entity-id') || 'missing id',
    entityName: getAttribute(element, 'data-pulse-entity-name'),
    entityTargetId:
      element.getAttribute('data-pulse-entity-target-id') || undefined,
    entityTargetName:
      element.getAttribute('data-pulse-entity-target-name') ||
      element.getAttribute('data-pulse-teaser-title') ||
      undefined,
    entityTargetUrl:
      element.getAttribute('data-pulse-entity-target-url') ||
      element.getAttribute('data-pulse-url') ||
      undefined,
    entityImageUrl:
      element.querySelector('img')?.getAttribute('src') || undefined,
    elementSize: element.getAttribute('data-pulse-element-size') || undefined,
    source: getAttribute(element, 'data-pulse-source'),
    position:
      getAttributeNumberOrNull(element, 'data-pulse-position') ??
      (getPositionFromSection(element) || undefined),
    positionInLevel:
      getAttributeNumberOrNull(element, 'data-pulse-position-in-level') ||
      undefined,
    positionInBundle:
      getAttributeNumberOrNull(element, 'data-pulse-position-in-bundle') ??
      undefined,
    accessLevel: getAttribute(element, 'data-pulse-access-level'),
    accessLevelName: getAttribute(element, 'data-pulse-access-level-name'),
    cardSize: getAttribute(element, 'data-pulse-card-size'),
    url: getUrl(element),
    teaserImage: getAttribute(element, 'data-pulse-teaser-image'),
    title: getAttribute(element, 'data-pulse-teaser-title'),
    sourceNewsroom: getAttribute(element, 'data-pulse-newsroom'),
    sourceArticleId: getAttribute(element, 'data-pulse-sourceId'),
    levelTemplateName: getAttribute(element, 'data-pulse-level-template-name'),
    teaserFeatures: getAttribute(element, 'data-pulse-teaser-features'),
    forceToAmplitude: getAttribute(element, 'data-pulse-force-to-amplitude'),
    clientName: getAttribute(element, 'data-pulse-client-name'),
    provider: getAttribute(element, 'data-provider'),
    placement: getAttribute(element, 'data-placement'),
    adVersion: getAttribute(element, 'data-pulse-ad-version'),
    drEditionTeaserId: getAttribute(element, 'data-dr-edition-id'),
    drEditionTeaserCreativeId: getAttribute(
      element,
      'data-dr-edition-creative-id',
    ),
    drEditionEditionId: getAttribute(element, 'data-dr-edition-editionId'),
    algorithm: getAttribute(element, 'data-dr-edition-algorithm'),
    productName: getAttribute(element, 'data-pulse-product-name'),
    bundleId: getAttribute(element, 'data-pulse-bundle-id'),
    withSummary: getAttribute(element, 'data-pulse-with-summary'),
    height: element.offsetHeight,
    width: element.offsetWidth,
    experiments: getAttributeJson<PulseExperiment[]>(
      element,
      'data-pulse-experiments',
    ),
    hasPersonalizationScore:
      getAttributeNumberOrNull(
        element,
        'data-pulse-has-personalization-score',
      ) ?? 0,
    seenCount: getAttributeNumberOrNull(element, 'data-pulse-seen-count') ?? 0,
    hasBeenRead:
      getAttributeNumberOrNull(element, 'data-pulse-has-been-read') ?? 0,
  };
}

export function createPulseTarget(
  pulseMeta: PulseAttributes,
  defaultNewsroom: string,
  targetNewsroom?: string,
): Target | undefined {
  switch (pulseMeta.entityType) {
    case 'ARTICLE':
    case 'AD':
      return createPulseTeaserTarget(
        pulseMeta,
        defaultNewsroom,
        targetNewsroom,
      );
    case 'LINK':
      return createPulseLinkTarget(pulseMeta);
    default:
      return undefined;
  }
}

function createPulseTeaserTarget(
  pulseMeta: PulseAttributes,
  defaultNewsroom: string,
  targetNewsroom?: string,
) {
  if (isNoContent(pulseMeta)) {
    return createPulseTeaserTargetNoContent(pulseMeta, defaultNewsroom);
  }

  const id =
    pulseMeta.entityType === 'AD'
      ? `sdrn:${defaultNewsroom}:article:${pulseMeta.drEditionTeaserId}` // Consider url instead to match article-view?
      : `sdrn:${defaultNewsroom}:article:${pulseMeta.entityId}`;

  return {
    id: id,
    type: 'Article',
    name: pulseMeta.entityTargetName || '',
    url: pulseMeta.entityTargetUrl || '',
    accessLevel: pulseMeta.accessLevel,
    accessLevelName: pulseMeta.accessLevelName,
    newsroom: targetNewsroom || defaultNewsroom,
  };
}

function createPulseTeaserTargetNoContent(
  pulseMeta: PulseAttributes,
  defaultNewsroom: string,
) {
  return {
    '@id': `sdrn:${defaultNewsroom}:article:${pulseMeta.drEditionTeaserId}`,
    '@type': 'Article',
    url: pulseMeta.url || '',
  };
}

function createPulseLinkTarget(pulseMeta: PulseAttributes) {
  if (!pulseMeta.url) {
    return undefined;
  }

  return {
    url: pulseMeta.url,
  };
}

export function createPulseObject(pulseMeta: PulseAttributes) {
  /*
   Currently source contains both source and context, but for placement they need to be split
   While sending both placement and position we need to separate the two
   In the future source & context should be different meta-fields
  */
  const splitSource = pulseMeta.source?.split(':') || [];
  const placementSource = splitSource.pop();
  const curateContext = splitSource.pop();

  const object = {
    placement: {
      primaryPosition: pulseMeta.position ?? -1,
      secondaryPosition: pulseMeta.positionInLevel,
      positionInBundle: pulseMeta.positionInBundle,
      curateContext,
      source: placementSource,
      bundleId: pulseMeta.bundleId,
    },
  };

  return pulseMeta.entityType === 'LINK'
    ? { ...object, ...createPulseLinkObject(pulseMeta) }
    : {
        ...object,
        ...createPulseTeaserObject(pulseMeta),
      };
}

function createPulseTeaserObject(pulseMeta: PulseAttributes) {
  if (isNoContent(pulseMeta)) {
    return createPulseTeaserObjectNoContent(pulseMeta);
  }

  const elementType = 'Teaser';

  return {
    id: pulseMeta.entityId || 'missing id',
    type: 'UIElement',
    name: pulseMeta.entityName || elementType,
    elementType,
    layout: {
      elementSize: pulseMeta.elementSize || pulseMeta.cardSize,
      imageUrl: pulseMeta.entityImageUrl || pulseMeta.teaserImage,
    },
    custom: {
      'spt:levelTemplateName': pulseMeta.levelTemplateName,
      'spt:teaserFeatures': pulseMeta.teaserFeatures,
      'spt:productName': pulseMeta.productName,
      'spt:bundleId': pulseMeta.bundleId,
      'spt:withSummary': pulseMeta.withSummary,
      'spt:height': pulseMeta.height,
      'spt:width': pulseMeta.width,
    },
    ...getPulseTags(pulseMeta),
    ...getCustomProperties(pulseMeta),
  };
}

function createPulseTeaserObjectNoContent(pulseMeta: PulseAttributes) {
  return {
    id: 'no-content',
    type: 'UIElement',
    name: 'no-content',
    elementType: 'no-content',
    ...getCustomProperties(pulseMeta),
  };
}

function createPulseLinkObject(pulseMeta: PulseAttributes) {
  return {
    id: `link:${pulseMeta.entityId}`,
    '@type': 'UIElement',
    name: pulseMeta.entityName ?? pulseMeta.entityId ?? '',
    elementType: 'Link',
    'spt:custom': {
      'spt:productName': pulseMeta.productName,
    },
  };
}

function getPositionFromSection(element: HTMLElement) {
  const sectionSelector: HTMLElement | null =
    element.closest('section.widgets');

  return sectionSelector
    ? getAttributeNumberOrNull(sectionSelector, 'data-pulse-position')
    : null;
}

function getPulseTags(pulseMeta: PulseAttributes) {
  if (pulseMeta.entityType === 'AD') {
    return {
      tags: [pulseMeta.clientName, pulseMeta.title, pulseMeta.url],
    };
  }

  return {};
}

function getCustomProperties(pulseMeta: PulseAttributes) {
  if (pulseMeta.entityType === 'AD') {
    const { drEditionTeaserId } = pulseMeta;

    return {
      'spt:custom': {
        publicationName: pulseMeta.provider,
        creativeId:
          drEditionTeaserId === 'no-content'
            ? drEditionTeaserId
            : pulseMeta.drEditionTeaserCreativeId ||
              (drEditionTeaserId && parseInt(drEditionTeaserId)) ||
              drEditionTeaserId,
        clientName: pulseMeta.clientName ? pulseMeta.clientName : '',
        adVersion: pulseMeta.adVersion,
      },
    };
  }

  return {};
}

export function getDrEditionProps(
  pulseMeta: PulseAttributes,
  device: string,
  eventType: string,
) {
  if (pulseMeta.entityType === 'AD') {
    const time = Math.floor(Date.now() / 1000);

    const {
      drEditionTeaserId: itemId,
      drEditionTeaserCreativeId: creativeId,
      url: link,
      title,
      placement,
      drEditionEditionId: editionId,
      algorithm,
    } = pulseMeta;

    return {
      engagement: 'UIElement',
      provider: {
        productTag: 'partnerstudio',
      },
      partnerstudio: {
        type: eventType,
        tracking_type: 'ad',
        time,
        placement,
        link,
        editionId,
        itemId,
        device,
        creativeId,
        xandrAdLoaded: false,
        title,
        algorithm,
      },
    };
  }

  return {};
}

function isNoContent(pulseMeta: PulseAttributes) {
  return (
    pulseMeta.entityType === 'AD' &&
    pulseMeta.entityId?.substr(0, 10) === 'no-content' &&
    pulseMeta.drEditionTeaserId === 'no-content'
  );
}
