import type { PulseExperiment } from '../../../../../public-src/core/js/pulse/pulse-util.js';

import { logger } from '../../../system/logger/logger.server.js';

type PulseAttributes = {
  entityId?: string;
  entityName?: string;
  entityType?: string;
  elementPosition?: number;
  source?: string;
  elementSize?: string;
  entityTargetId?: string;
  entityTargetName?: string;
  entityTargetUrl?: string;
  elementPositionInBundle?: number;
  entityAccessLevel?: string;
  entityAccessLevelName?: string;
  entityImageUrl?: string;
  entitySize?: { height: number; width: number };
  elementBundleId?: string;
  hasPersonalizationScore: number;
  seenCount: number;
  hasBeenRead: number;
  experiments?: PulseExperiment[];
};

function getAttributeNumber(
  element: Element,
  attributeName: string,
): number | undefined {
  return element.hasAttribute(attributeName)
    ? Number(element.getAttribute(attributeName))
    : undefined;
}

function getAttributeJson<T>(
  element: Element,
  attributeName: string,
): T | undefined {
  const stringValue = element.getAttribute(attributeName);

  if (!stringValue) {
    return undefined;
  }

  let value = undefined;

  try {
    value = JSON.parse(stringValue);
  } catch (error) {
    logger.writeError(
      'Error parsing JSON pulse metadata: ',
      stringValue,
      error,
    );
  }

  return value;
}

const getPulseAttributesFromElement = (element: Element): PulseAttributes => {
  return {
    entityId: element.getAttribute('data-pulse-entity-id') || undefined,
    entityName: element.getAttribute('data-pulse-entity-name') || undefined,
    entityType: element.getAttribute('data-pulse-entity-type') || undefined,
    elementPosition: getAttributeNumber(element, 'data-pulse-position'),
    source: element.getAttribute('data-pulse-source') || undefined,
    elementSize: element.getAttribute('data-pulse-element-size') || undefined,
    entityTargetId:
      element.getAttribute('data-pulse-entity-target-id') || undefined,
    entityTargetName:
      element.getAttribute('data-pulse-entity-target-name') || undefined,
    entityTargetUrl:
      element.getAttribute('data-pulse-entity-target-url') || undefined,
    elementPositionInBundle: getAttributeNumber(
      element,
      'data-pulse-position-in-bundle',
    ),
    entityAccessLevel:
      element.getAttribute('data-pulse-access-level') || undefined,
    entityAccessLevelName:
      element.getAttribute('data-pulse-access-level-name') || undefined,
    entityImageUrl:
      element.querySelector('img')?.getAttribute('src') || undefined,
    entitySize: { height: element.clientHeight, width: element.clientWidth },
    elementBundleId: element.getAttribute('data-pulse-bundle-id') || undefined,
    hasPersonalizationScore:
      getAttributeNumber(element, 'data-pulse-has-personalization-score') || 0,
    seenCount: getAttributeNumber(element, 'data-pulse-seen-count') || 0,
    hasBeenRead: getAttributeNumber(element, 'data-pulse-has-been-read') || 0,
    experiments: getAttributeJson<PulseExperiment[]>(
      element,
      'data-pulse-experiments',
    ),
  };
};

export { getPulseAttributesFromElement, type PulseAttributes };
