export const isLocalStorageSupported =
  typeof window !== 'undefined' &&
  typeof window.localStorage?.getItem === 'function' &&
  typeof window.localStorage?.setItem === 'function';

export const getItemFromLocalStorage = (key: string): string | null => {
  if (!isLocalStorageSupported) {
    console.error('localStorage is not supported');

    return null;
  }

  try {
    return window.localStorage.getItem(key);
  } catch (error) {
    console.error(`Error getting item "${key}" from localStorage:`, error);

    return null;
  }
};

export const saveItemToLocalStorage = (key: string, value: string): void => {
  if (!isLocalStorageSupported) {
    console.error('localStorage is not supported');

    return;
  }

  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    console.error(`Error saving item "${key}" to localStorage:`, error);
  }
};

export const removeItemFromLocalStorage = (key: string): void => {
  if (!isLocalStorageSupported) {
    console.error('localStorage is not supported');

    return;
  }

  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing item "${key}" from localStorage:`, error);
  }
};
