import type { InternalLogger } from './types.js';

export const LOG_INFO = 'info';
export const LOG_WARNING = 'warning';
export const LOG_ERROR = 'error';

export const ALLOWED_LOG_LEVELS = [LOG_INFO, LOG_WARNING, LOG_ERROR];

export const logLevelNumbers = {
  [LOG_INFO]: 20,
  [LOG_WARNING]: 30,
  [LOG_ERROR]: 40,
};

// log output interface, console.log by default
export const internalLogger: InternalLogger = {
  /* eslint-disable no-restricted-globals */
  [LOG_INFO]: console.info.bind(console),
  [LOG_WARNING]: console.warn.bind(console),
  [LOG_ERROR]: console.error.bind(console),
  /* eslint-enable no-restricted-globals */
};
