/* eslint-disable */
//@ts-nocheck
/* global pulse */
const setupDefaultValues = (): void => {
  'use strict';
  const _createClass = (function () {
    function defineProperties(target, props) {
      for (let i = 0; i < props.length; i++) {
        const descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }
    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  })();
  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }
  const DEFAULT_CONFIG = {
    objectType: undefined,
  };
  // start fasten custom override
  const PopulateAbTestPlugin = (function () {
    function PopulateAbTestPlugin(tracker) {
      const config =
        arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      _classCallCheck(this, PopulateAbTestPlugin);
      this.tracker = tracker;
      this.updateTrackerAbTestMeta(config);
    }
    _createClass(PopulateAbTestPlugin, [
      {
        key: 'updateTrackerAbTestMeta',
        value: function updateTrackerAbTestMeta() {
          const _ref =
              arguments.length > 0 && arguments[0] !== undefined
                ? arguments[0]
                : {},
            abTestValue = _ref.abTestValue,
            userABGroup = _ref.userABGroup;
          const customObject =
            userABGroup === ''
              ? {
                  abTestValue: abTestValue,
                }
              : {
                  abTestValue: abTestValue,
                  userABGroup: userABGroup,
                };
          this.tracker.update(
            {
              object: {
                custom: customObject,
              },
            },
            true,
          ); //preserve without full promise chain flow
        },
      },
    ]);
    return PopulateAbTestPlugin;
  })();
  pulse('provide', 'helloAbTest', PopulateAbTestPlugin);
  // end fasten custom override
  function recognizedObjectType(type) {
    return type === 'Article' || type === 'Page' || type === 'Frontpage';
  }
  // Pulse Engage Default values plugin, version: 1.0.1
  // http://sdk.pulse.schibsted.com/plugins/populate-default-engage-values/plugin.js
  const PopulateDefaultValuesPlugin = (function () {
    function PopulateDefaultValuesPlugin(tracker) {
      const config =
        arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      _classCallCheck(this, PopulateDefaultValuesPlugin);
      this.tracker = tracker;
      this.config = Object.assign({}, DEFAULT_CONFIG, config);
      this.updateTrackerState();
    }
    _createClass(PopulateDefaultValuesPlugin, [
      {
        key: 'updateTrackerState',
        value: function updateTrackerState() {
          const _this = this;
          const defaultValuesPromise = this.tracker.evaluateEventInputs();
          this.tracker.update(
            {
              provider: defaultValuesPromise.then(function (event) {
                return recognizedObjectType(
                  _this.config.objectType || event.object.type,
                )
                  ? {
                      'spt:engage': event.provider.id,
                      product: 'fastenposten',
                      productType: 'ResponsiveWeb',
                    }
                  : {};
              }),
              object: defaultValuesPromise.then(function () {
                const _ref =
                    arguments.length > 0 && arguments[0] !== undefined
                      ? arguments[0]
                      : {},
                  object = _ref.object,
                  device = _ref.device,
                  provider = _ref.provider,
                  origin = _ref.origin;

                return {
                  custom: recognizedObjectType(
                    _this.config.objectType || object.type,
                  )
                    ? {
                        'spt:device': device.deviceType,
                        'spt:permalink': object.url,
                        'spt:referrer': origin.url,
                        'spt:site': provider.id,
                        'spt:url': object.url,
                        'spt:articleId':
                          object.type === 'Article' ? object.id : undefined,
                        'spt:pageId':
                          object.type === 'Page' || object.type === 'Frontpage'
                            ? object.id
                            : undefined,
                      }
                    : {},
                };
              }),
            },
            true,
          );
        },
      },
    ]);
    return PopulateDefaultValuesPlugin;
  })();
  /* global pulse */
  pulse('provide', 'populateDefaultValues', PopulateDefaultValuesPlugin);
};
// Pulse Local history plugin, version: 1.0.2
// http://sdk.pulse.schibsted.com/plugins/local-history/plugin.js
const localHistoryPlugin = (): void => {
  const commonjsGlobal =
    typeof window !== 'undefined'
      ? window
      : typeof global !== 'undefined'
        ? global
        : typeof self !== 'undefined'
          ? self
          : {};
  const assign = make_assign();
  const create$1 = make_create();
  const trim = make_trim();
  const Global = typeof window !== 'undefined' ? window : commonjsGlobal;
  const util = {
    assign: assign,
    create: create$1,
    trim: trim,
    bind: bind$1,
    slice: slice$1,
    each: each$1,
    map: map,
    pluck: pluck$1,
    isList: isList$1,
    isFunction: isFunction$1,
    isObject: isObject$1,
    Global: Global,
  };
  function make_assign() {
    if (Object.assign) {
      return Object.assign;
    } else {
      return function shimAssign(obj, props1, props2, etc) {
        for (let i = 1; i < arguments.length; i++) {
          each$1(Object(arguments[i]), function (val, key) {
            obj[key] = val;
          });
        }
        return obj;
      };
    }
  }
  function make_create() {
    if (Object.create) {
      return function create(obj, assignProps1, assignProps2, etc) {
        const assignArgsList = slice$1(arguments, 1);
        return assign.apply(this, [Object.create(obj)].concat(assignArgsList));
      };
    } else {
      function F() {} // eslint-disable-line no-inner-declarations
      return function create(obj, assignProps1, assignProps2, etc) {
        const assignArgsList = slice$1(arguments, 1);
        F.prototype = obj;
        return assign.apply(this, [new F()].concat(assignArgsList));
      };
    }
  }
  function make_trim() {
    if (String.prototype.trim) {
      return function trim(str) {
        return String.prototype.trim.call(str);
      };
    } else {
      return function trim(str) {
        return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
      };
    }
  }
  function bind$1(obj, fn) {
    return function () {
      return fn.apply(obj, Array.prototype.slice.call(arguments, 0));
    };
  }
  function slice$1(arr, index) {
    return Array.prototype.slice.call(arr, index || 0);
  }
  function each$1(obj, fn) {
    pluck$1(obj, function (val, key) {
      fn(val, key);
      return false;
    });
  }
  function map(obj, fn) {
    const res = isList$1(obj) ? [] : {};
    pluck$1(obj, function (v, k) {
      res[k] = fn(v, k);
      return false;
    });
    return res;
  }
  function pluck$1(obj, fn) {
    if (isList$1(obj)) {
      for (let i = 0; i < obj.length; i++) {
        if (fn(obj[i], i)) {
          return obj[i];
        }
      }
    } else {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (fn(obj[key], key)) {
            return obj[key];
          }
        }
      }
    }
  }
  function isList$1(val) {
    return (
      val != null && typeof val != 'function' && typeof val.length == 'number'
    );
  }
  function isFunction$1(val) {
    return val && {}.toString.call(val) === '[object Function]';
  }
  function isObject$1(val) {
    return val && {}.toString.call(val) === '[object Object]';
  }
  const slice = util.slice;
  const pluck = util.pluck;
  const each = util.each;
  const bind = util.bind;
  const create = util.create;
  const isList = util.isList;
  const isFunction = util.isFunction;
  const isObject = util.isObject;
  const storeEngine = {
    createStore: createStore,
  };
  const storeAPI = {
    version: '2.0.12',
    enabled: false,
    // get returns the value of the given key. If that value
    // is undefined, it returns optionalDefaultValue instead.
    get: function (key, optionalDefaultValue) {
      const data = this.storage.read(this._namespacePrefix + key);
      return this._deserialize(data, optionalDefaultValue);
    },
    // set will store the given value at key and returns value.
    // Calling set with value === undefined is equivalent to calling remove.
    set: function (key, value) {
      if (value === undefined) {
        return this.remove(key);
      }
      this.storage.write(this._namespacePrefix + key, this._serialize(value));
      return value;
    },
    // remove deletes the key and value stored at the given key.
    remove: function (key) {
      this.storage.remove(this._namespacePrefix + key);
    },
    // each will call the given callback once for each key-value pair
    // in this store.
    each: function (callback) {
      const self = this;
      this.storage.each(function (val, namespacedKey) {
        callback.call(
          self,
          self._deserialize(val),
          (namespacedKey || '').replace(self._namespaceRegexp, ''),
        );
      });
    },
    // clearAll will remove all the stored key-value pairs in this store.
    clearAll: function () {
      this.storage.clearAll();
    },
    // additional functionality that can't live in plugins
    // ---------------------------------------------------
    // hasNamespace returns true if this store instance has the given namespace.
    hasNamespace: function (namespace) {
      return this._namespacePrefix == '__storejs_' + namespace + '_';
    },
    // createStore creates a store.js instance with the first
    // functioning storage in the list of storage candidates,
    // and applies the the given mixins to the instance.
    createStore: function () {
      return createStore.apply(this, arguments);
    },
    addPlugin: function (plugin) {
      this._addPlugin(plugin);
    },
    namespace: function (namespace) {
      return createStore(this.storage, this.plugins, namespace);
    },
  };
  function _warn() {
    const _console = typeof console == 'undefined' ? null : console;
    if (!_console) {
      return;
    }
    const fn = _console.warn ? _console.warn : _console.log;
    fn.apply(_console, arguments);
  }
  function createStore(storages, plugins, namespace) {
    if (!namespace) {
      namespace = '';
    }
    if (storages && !isList(storages)) {
      storages = [storages];
    }
    if (plugins && !isList(plugins)) {
      plugins = [plugins];
    }
    const namespacePrefix = namespace ? '__storejs_' + namespace + '_' : '';
    const namespaceRegexp = namespace
      ? new RegExp('^' + namespacePrefix)
      : null;
    const legalNamespaces = /^[a-zA-Z0-9_\-]*$/; // alpha-numeric + underscore and dash
    if (!legalNamespaces.test(namespace)) {
      throw new Error(
        'store.js namespaces can only have alphanumerics + underscores and dashes',
      );
    }
    const _privateStoreProps = {
      _namespacePrefix: namespacePrefix,
      _namespaceRegexp: namespaceRegexp,
      _testStorage: function (storage) {
        try {
          const testStr = '__storejs__test__';
          storage.write(testStr, testStr);
          const ok = storage.read(testStr) === testStr;
          storage.remove(testStr);
          return ok;
        } catch (e) {
          return false;
        }
      },
      _assignPluginFnProp: function (pluginFnProp, propName) {
        const oldFn = this[propName];
        this[propName] = function pluginFn() {
          const args = slice(arguments, 0);
          const self = this;
          // super_fn calls the old function which was overwritten by
          // this mixin.
          function super_fn() {
            if (!oldFn) {
              return;
            }
            each(arguments, function (arg, i) {
              args[i] = arg;
            });
            return oldFn.apply(self, args);
          }
          // Give mixing function access to super_fn by prefixing all mixin function
          // arguments with super_fn.
          const newFnArgs = [super_fn].concat(args);
          return pluginFnProp.apply(self, newFnArgs);
        };
      },
      _serialize: function (obj) {
        return JSON.stringify(obj);
      },
      _deserialize: function (strVal, defaultVal) {
        if (!strVal) {
          return defaultVal;
        }
        // It is possible that a raw string value has been previously stored
        // in a storage without using store.js, meaning it will be a raw
        // string value instead of a JSON serialized string. By defaulting
        // to the raw string value in case of a JSON parse error, we allow
        // for past stored values to be forwards-compatible with store.js
        let val = '';
        try {
          val = JSON.parse(strVal);
        } catch (e) {
          val = strVal;
        }
        return val !== undefined ? val : defaultVal;
      },
      _addStorage: function (storage) {
        if (this.enabled) {
          return;
        }
        if (this._testStorage(storage)) {
          this.storage = storage;
          this.enabled = true;
        }
      },
      _addPlugin: function (plugin) {
        const self = this;
        // If the plugin is an array, then add all plugins in the array.
        // This allows for a plugin to depend on other plugins.
        if (isList(plugin)) {
          each(plugin, function (plugin) {
            self._addPlugin(plugin);
          });
          return;
        }
        // Keep track of all plugins we've seen so far, so that we
        // don't add any of them twice.
        const seenPlugin = pluck(this.plugins, function (seenPlugin) {
          return plugin === seenPlugin;
        });
        if (seenPlugin) {
          return;
        }
        this.plugins.push(plugin);
        // Check that the plugin is properly formed
        if (!isFunction(plugin)) {
          throw new Error(
            'Plugins must be function values that return objects',
          );
        }
        const pluginProperties = plugin.call(this);
        if (!isObject(pluginProperties)) {
          throw new Error(
            'Plugins must return an object of function properties',
          );
        }
        // Add the plugin function properties to this store instance.
        each(pluginProperties, function (pluginFnProp, propName) {
          if (!isFunction(pluginFnProp)) {
            throw new Error(
              'Bad plugin property: ' +
                propName +
                ' from plugin ' +
                plugin.name +
                '. Plugins should only return functions.',
            );
          }
          self._assignPluginFnProp(pluginFnProp, propName);
        });
      },
      // Put deprecated properties in the private API, so as to not expose it to accidential
      // discovery through inspection of the store object.
      // Deprecated: addStorage
      addStorage: function (storage) {
        _warn(
          'store.addStorage(storage) is deprecated. Use createStore([storages])',
        );
        this._addStorage(storage);
      },
    };
    const store = create(_privateStoreProps, storeAPI, {
      plugins: [],
    });
    store.raw = {};
    each(store, function (prop, propName) {
      if (isFunction(prop)) {
        store.raw[propName] = bind(store, prop);
      }
    });
    each(storages, function (storage) {
      store._addStorage(storage);
    });
    each(plugins, function (plugin) {
      store._addPlugin(plugin);
    });
    return store;
  }
  const Global$1 = util.Global;
  const localStorage_1 = {
    name: 'localStorage',
    read: read,
    write: write,
    each: each$2,
    remove: remove,
    clearAll: clearAll,
  };
  function localStorage() {
    return Global$1.localStorage;
  }
  function read(key) {
    return localStorage().getItem(key);
  }
  function write(key, data) {
    return localStorage().setItem(key, data);
  }
  function each$2(fn) {
    for (let i = localStorage().length - 1; i >= 0; i--) {
      const key = localStorage().key(i);
      fn(read(key), key);
    }
  }
  function remove(key) {
    return localStorage().removeItem(key);
  }
  function clearAll() {
    return localStorage().clear();
  }
  // cookieStorage is useful Safari private browser mode, where localStorage
  // doesn't work but cookies do. This implementation is adopted from
  // https://developer.mozilla.org/en-US/docs/Web/API/Storage/LocalStorage
  const Global$2 = util.Global;
  const trim$1 = util.trim;
  const cookieStorage = {
    name: 'cookieStorage',
    read: read$1,
    write: write$1,
    each: each$3,
    remove: remove$1,
    clearAll: clearAll$1,
  };
  const doc = Global$2.document;
  function read$1(key) {
    if (!key || !_has(key)) {
      return null;
    }
    const regexpStr =
      '(?:^|.*;\\s*)' +
      escape(key).replace(/[\-\.\+\*]/g, '\\$&') +
      '\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*';
    return unescape(doc.cookie.replace(new RegExp(regexpStr), '$1'));
  }
  function each$3(callback) {
    const cookies = doc.cookie.split(/; ?/g);
    for (let i = cookies.length - 1; i >= 0; i--) {
      if (!trim$1(cookies[i])) {
        continue;
      }
      const kvp = cookies[i].split('=');
      const key = unescape(kvp[0]);
      const val = unescape(kvp[1]);
      callback(val, key);
    }
  }
  function write$1(key, data) {
    if (!key) {
      return;
    }
    doc.cookie =
      escape(key) +
      '=' +
      escape(data) +
      '; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/';
  }
  function remove$1(key) {
    if (!key || !_has(key)) {
      return;
    }
    doc.cookie =
      escape(key) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  }
  function clearAll$1() {
    each$3(function (_, key) {
      remove$1(key);
    });
  }
  function _has(key) {
    return new RegExp(
      '(?:^|;\\s*)' + escape(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=',
    ).test(doc.cookie);
  }
  const _createClass = (function () {
    function defineProperties(target, props) {
      for (let i = 0; i < props.length; i++) {
        const descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }
    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  })();
  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }
  /* eslint-env browser */
  const ARTICLE_STORAGE_KEY = 'previousArticle';
  const ARTICLE_PATH = '/i/';
  const ARTICLE_PREFIX = 'html';
  const PAGE_STORAGE_KEY = 'previousPage';
  const PAGE_TYPES = ['Page', 'Frontpage', 'Listing'];
  const IMPRESSION_STORAGE_KEY = 'referringImpression';
  const DEFAULT_CONFIG = {
    objectType: undefined,
  };
  function getDomainFromUrl(url) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    return a.hostname;
  }
  function urlExists(url) {
    return Boolean(url) && url.length > 0;
  }
  function userCameFromTheSameDomain(referrer, url) {
    return (
      urlExists(referrer) &&
      urlExists(url) &&
      getDomainFromUrl(referrer) === getDomainFromUrl(url)
    );
  }
  function urlHasArticlePath(url) {
    return (
      Boolean(url) &&
      (url.indexOf(ARTICLE_PATH) > 0 || url.indexOf(ARTICLE_PREFIX) > 0)
    );
  }
  function userCameFromArticlePath(referrer, url) {
    return (
      urlExists(referrer) &&
      urlExists(url) &&
      urlHasArticlePath(referrer) &&
      urlHasArticlePath(url)
    );
  }
  const LocalHistoryPlugin = (function () {
    function LocalHistoryPlugin(tracker) {
      const config =
        arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      _classCallCheck(this, LocalHistoryPlugin);
      this.tracker = tracker;
      this.store = storeEngine.createStore([localStorage_1, cookieStorage]);
      this.config = Object.assign({}, DEFAULT_CONFIG, config);
      this.update();
    }
    _createClass(LocalHistoryPlugin, [
      {
        key: 'update',
        value: function update() {
          this.updateTrackerState();
          this.updatePersistedHistory();
        },
        /**
         * Read previous state from local storage and update tracker state
         */
      },
      {
        key: 'updateTrackerState',
        value: function updateTrackerState() {
          const _this = this;
          this.tracker.update(
            {
              object: {
                custom: new Promise(function (resolve, reject) {
                  _this.tracker
                    .evaluateEventInputs()
                    .then(function (event) {
                      const noHistory = {
                        'spt:url': event.object.url,
                        'spt:referrer': '',
                        'spt:isReferrerArticle': false,
                        'spt:referrerId': '',
                        'spt:previousReferrer': null,
                        'spt:referringImpression': null,
                      };
                      if (
                        !userCameFromTheSameDomain(
                          event.origin.url,
                          event.object.url,
                        )
                      ) {
                        // Only use the persisted history of the referrer is from the same page
                        return resolve(noHistory);
                      }
                      const _ref = _this.config.objectType
                          ? {
                              type: _this.config.objectType,
                            }
                          : event.object,
                        type = _ref.type;
                      if (type === 'Article' || type === 'SalesPoster') {
                        const referringPage = _this.store.get(PAGE_STORAGE_KEY);
                        let referringArticle =
                          _this.store.get(ARTICLE_STORAGE_KEY);
                        if (referringArticle) {
                          if (
                            !userCameFromArticlePath(
                              event.origin.url,
                              referringArticle.referrer,
                            )
                          ) {
                            // Only use the persisted history if the referrer is from the an article
                            referringArticle = false;
                          }
                        }
                        const referrer = referringPage || referringArticle;
                        const referringImpression = _this.store.get(
                          IMPRESSION_STORAGE_KEY,
                        );
                        return resolve({
                          'spt:url': event.object.url,
                          'spt:referrer': event.origin.url,
                          'spt:isReferrerArticle': Boolean(referringArticle),
                          'spt:referrerId': referrer
                            ? referrer.id
                            : event.origin.url,
                          'spt:previousReferrer': referrer
                            ? referrer.referrer
                            : null,
                          'spt:referringImpression': referringImpression
                            ? referringImpression
                            : null,
                        });
                      } else if (PAGE_TYPES.indexOf(type) !== -1) {
                        return resolve({
                          'spt:url': event.object.url,
                          'spt:referrer': event.origin.url,
                        });
                      }
                      return resolve(noHistory);
                    })
                    .catch(reject);
                }),
              },
            },
            true,
          );
        },
        /**
         * Persist current tracker state to local storage for use later.
         */
      },
      {
        key: 'updatePersistedHistory',
        value: function updatePersistedHistory() {
          const _this2 = this;
          this.tracker.evaluateEventInputs().then(function (event) {
            const type = event.object.type;
            if (type === 'Article') {
              _this2.updatePersistedArticleHistory();
            } else if (PAGE_TYPES.indexOf(type) !== -1) {
              _this2.updatePersistedPageHistory();
            } else if (type === 'SalesPoster') {
              _this2.updatePersistedSalesPosterHistory();
            }
          });
        },
      },
      {
        key: 'updatePersistedPageHistory',
        value: function updatePersistedPageHistory() {
          const _this3 = this;
          this.store.set(ARTICLE_STORAGE_KEY, null);
          this.store.set(IMPRESSION_STORAGE_KEY, null);
          this.tracker.evaluateEventInputs().then(function (event) {
            _this3.store.set(PAGE_STORAGE_KEY, {
              // url: _this3.url,
              // referrer: _this3.referrer,
              // for Hyperion: plugin v1.0.9 vs .2 tweak
              url: event.object.url,
              referrer: event.origin.url,
              id: event.object.id,
              // for hyperion reading logic
              type: event.object.type,
            });
          });
        },
      },
      {
        key: 'updatePersistedArticleHistory',
        value: function updatePersistedArticleHistory() {
          const _this4 = this;
          this.store.set(PAGE_STORAGE_KEY, null);
          this.store.set(IMPRESSION_STORAGE_KEY, null);
          this.tracker.evaluateEventInputs().then(function (event) {
            _this4.store.set(ARTICLE_STORAGE_KEY, {
              url: event.object.url,
              referrer: event.origin.url,
              id: event.object.id,
            });
          });
        },
      },
      {
        key: 'updatePersistedSalesPosterHistory',
        value: function updatePersistedSalesPosterHistory() {
          const _this5 = this;
          // retain front or article info - patches Recirc bug
          // this.store.set(PAGE_STORAGE_KEY, null);
          // this.store.set(IMPRESSION_STORAGE_KEY, null);
        },
      },
      {
        key: 'updatePersistedImpressionHistory',
        value: function updatePersistedImpressionHistory() {
          const _this6 = this;
          const _ref =
            arguments.length > 0 && arguments[0] !== undefined
              ? arguments[0]
              : {};
          if (_ref && _ref.hasOwnProperty('impression')) {
            const impression = _ref.impression;
            this.tracker.evaluateEventInputs().then(function (event) {
              impression.pageViewId = _this6.tracker.getPageViewId();
              impression.url = event.object.url;
              impression.referrer = event.origin.url;
              _this6.store.set(IMPRESSION_STORAGE_KEY, impression);
            });
          }
        },
      },
    ]);
    return LocalHistoryPlugin;
  })();
  /* global pulse */
  pulse('provide', 'localHistory', LocalHistoryPlugin);
};

export const initPulseEngageAddon = (): void => {
  setupDefaultValues();
  localHistoryPlugin();
};
