import { NewsMediaPulseTracker } from '@schibsted/pulse-news-media';
import { Tracker as PulseTracker } from '@spt-tracking/pulse-sdk';

import type { SDKConfigInput } from '@spt-tracking/pulse-sdk';
import type {
  DeployStage,
  PulseAutotracker,
  TrackerConfig,
} from '@schibsted/pulse-news-media/lib/types/types/index.js';

import { getActorPromise } from '../../../../src/core/mandatory-integrations/pulse/utils/index.js';
import { frontendConfig } from '../../../../src/core/system/config/frontend.server.js';
import { isLocalhost, isReviewApp } from '../utils/is-localhost.js';

let newsMediaPulseTracker: Promise<NewsMediaPulseTracker> | undefined;

export const getPulseSdkConfigInput = (): SDKConfigInput => ({
  nativeJwe: window.hermesJwe,
  altEventHandler: window.pulseEventHandler,
  autoCreateConsents: true,
  requireAdvertisingOptIn: true,
});

const createTrackerConfig = (deployStage: DeployStage): TrackerConfig => {
  const { providerId } = frontendConfig.pulseConfig;

  const actorPromise = getActorPromise();
  const productName = 'fastenposten';

  return {
    deployStage,
    providerId,
    productName,
    builders: {
      actor: actorPromise,
    },
    sdkConfigInput: getPulseSdkConfigInput(),
  };
};

export const getNewsMediaPulseTracker = async () => {
  if (!newsMediaPulseTracker) {
    const { deployStage, deployTag } = getDeployInfo();

    const trackerConfig = createTrackerConfig(deployStage);

    newsMediaPulseTracker = NewsMediaPulseTracker.fromAutotracker(
      window.pulse as PulseAutotracker,
      trackerConfig,
    ).then((tracker) => {
      if (deployStage === 'dev') {
        tracker.updateBaseEvent({ deployTag, deployStage });
      }

      return tracker;
    });
  }

  return newsMediaPulseTracker;
};

export const getNewsMediaPulseTrackerFromClass = async () => {
  if (!newsMediaPulseTracker) {
    const { deployStage, deployTag } = getDeployInfo();

    const trackerConfig = createTrackerConfig(deployStage);

    const newsMediaTracker = NewsMediaPulseTracker.fromPulseTracker(
      PulseTracker,
      trackerConfig,
    );

    if (deployStage === 'dev') {
      newsMediaTracker.updateBaseEvent({ deployTag, deployStage });
    }

    newsMediaPulseTracker = Promise.resolve(newsMediaTracker);
  }

  return newsMediaPulseTracker;
};

export function getDeployInfo(): {
  deployStage: 'dev' | 'pre' | 'pro';
  deployTag: string;
} {
  const host = window.location.hostname;

  if (isLocalhost()) {
    return { deployStage: 'dev', deployTag: 'fasten-dev' };
  } else if (isReviewApp()) {
    return { deployStage: 'dev', deployTag: 'fasten-pr' };
  } else if (host.startsWith('alpha.')) {
    return { deployStage: 'dev', deployTag: 'fasten-alpha' };
  } else if (host.startsWith('beta.')) {
    return { deployStage: 'dev', deployTag: 'fasten-beta' };
  } else {
    return { deployStage: 'pro', deployTag: 'fasten-prod' };
  }
}
