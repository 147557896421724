import { createEmbedScript } from './pulse-create-embed-script.js';

export const loadPulse = (): void => {
  createEmbedScript(
    window,
    document,
    'pulse',
    'script',
    '//sdk.pulse.schibsted.com/versioned/3/pulse.min.js',
  );
};
