import type { Logger } from './types.js';

import { LOG_ERROR, LOG_INFO, LOG_WARNING } from './constants.js';
import { createLogFunction } from './logger.js';

/* eslint-disable no-restricted-globals */
export const logger: Logger = {
  write: createLogFunction(LOG_INFO, LOG_INFO),
  writeError: createLogFunction(LOG_ERROR, LOG_ERROR),
  writeWarning: createLogFunction(LOG_WARNING, LOG_WARNING),
};
export const setLoggerTo = () => undefined;
