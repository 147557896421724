import { datadogLogs } from '@datadog/browser-logs';
import { ConsentRegistry } from '@schibsted/consent-registry';

import { frontendConfig } from '../../src/core/system/config/frontend.server.js';

export async function initializeBrowserLogs(
  options: Partial<Parameters<typeof datadogLogs.init>[0]> = {},
): Promise<void> {
  const {
    publication,
    serverRuntime,
    browserLogs: { clientToken },
  } = frontendConfig;

  if (!clientToken) {
    return;
  }

  const [consentGranted] = await ConsentRegistry.useIntegration(
    'Datadog',
    () => {
      datadogLogs.setTrackingConsent('not-granted');
    },
  );

  if (consentGranted) {
    datadogLogs.init({
      clientToken,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sessionSampleRate: 1,
      service: publication,
      env: serverRuntime,
      ...options,
    });
  }
}
